<template>
  <div class="row h-100 justify-content-between">
    <div class="d-none d-lg-block col-lg-2 col-xl-2 text-white align-items-stretch bg-white">
      <div class="pl-4">
        <Phaseline
            :phases="process.processPhases"
            :processId="process.id"
            :processLogo="getProcessLogo">
          >
        </Phaseline>
      </div>
    </div>
    <div class="col-md-7 col-lg-10 col-xl-10">
      <div class="d-flex flex-column px-6 mt-10">
        <!--::Process Info -->
        <div class="card mb-5 shadow-sm ribbon ribbon-clip ribbon-right">

          <!--Ribbon right-->
          <div v-if="this.versionNumber && this.versionNumber > 0" class="ribbon-target p-3" style="top: 12px;">
            <span class="ribbon-inner bg-primary"></span>
            <span class="text-uppercase font-weight-bolder h3">{{this.versionNumber+1}}</span>
          </div>
          <!--end::Ribbon right-->

          <div class="card-header border-0">
            <div class="row">
              <div class="col-12">
                <h3 class="text-dark text-hover-primary font-size-h3 font-weight-bolder mb-1 text-secondary">
                  {{ $t("MODULEIDEAVERSIONING.NUOVAVERSIONE") }}
                </h3>
                <span v-if="this.versionNumber && this.versionNumber > 0">{{$t("MODULEIDEAVERSIONING.NUOVAVERSIONEMESSAGGIO1")}} <b>{{$t("MODULEIDEAVERSIONING.NUOVAVERSIONEMESSAGGIO2")  + ' ' + (this.versionNumber+1)}}</b> {{$t("MODULE" + $handleContentTypeLabel(moduleContentType) + "VERSIONING.NUOVAVERSIONEMESSAGGIO3")}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex flex-column px-6 flex-wrap mb-5">
        <div class="card card-custom card-transparent">
          <div class="card-body p-0">
            <!--: Wizard-->
            <div
                class="wizard wizard-4"
                id="kt_wizard_v4"
                data-wizard-state="step-first"
                data-wizard-clickable="true"
            >
              <!--: Wizard Nav-->
              <div class="wizard-nav">
                <div class="wizard-steps justify-content-start">

                  <!--Istruzioni-->
                  <div v-if="this.contentTypeInstructions && this.contentTypeInstructions!=''" class="wizard-step col-12 col-md-3 col-lg-3 col-xl-3" data-wizard-type="step" data-wizard-state="current">
                    <div class="wizard-wrapper">
<!--                      <div class="wizard-number">-->
<!--                        1-->
<!--                      </div>-->
                      <div class="wizard-label">
                        <div class="wizard-title">
<!--                          {{$t("MODULEIDEAVERSIONING.ISTRUZIONI")}}-->
                          {{ stepA }}
                        </div>
                      </div>
                    </div>
                  </div>

                  <!--Sfide-->
                  <div class="wizard-step col-12" :class="{'col-md-2 col-lg-2 col-xl-2': this.contentTypeInstructions && this.contentTypeInstructions!='', 'col-md-3 col-lg-3 col-xl-3': this.contentTypeInstructions == ''}" data-wizard-type="step" :data-wizard-state="(this.contentTypeInstructions && this.contentTypeInstructions!='') ? '' : 'current'" v-if="challenges && challenges.length > 0">
                    <div class="wizard-wrapper">
<!--                      <div class="wizard-number">-->
<!--                        {{(this.contentTypeInstructions && this.contentTypeInstructions!='') ? 2 : 1}}-->
<!--                      </div>-->
                      <div class="wizard-label">
                        <div class="wizard-title">
<!--                          {{$t("MODULEIDEAVERSIONING.CHALLENGE")}}-->
                          {{ stepB }}
                        </div>
                      </div>
                    </div>
                  </div>

                  <!--Contesto-->
                  <div v-if="scenarios && scenarios.length > 0 || displayViewMap" class="wizard-step col-12" :class="{'col-md-2 col-lg-2 col-xl-2': this.contentTypeInstructions && this.contentTypeInstructions!='', 'col-md-3 col-lg-3 col-xl-3': this.contentTypeInstructions == ''}" data-wizard-type="step">
                    <div class="wizard-wrapper">
<!--                      <div class="wizard-number">-->
<!--                        {{(this.contentTypeInstructions && this.contentTypeInstructions!='') ? 3 : 2}}-->
<!--                      </div>-->
                      <div class="wizard-label">
                        <div class="wizard-title">
<!--                          {{$t("MODULEIDEAVERSIONING.MAPPEEINFO")}}-->
                          {{ stepC }}
                        </div>
                      </div>
                    </div>
                  </div>

                  <!--Descrizione-->
                  <div class="wizard-step col-12" :class="{'col-md-2 col-lg-2 col-xl-2': this.contentTypeInstructions && this.contentTypeInstructions!='', 'col-md-3 col-lg-3 col-xl-3': this.contentTypeInstructions == ''}" data-wizard-type="step">
                    <div class="wizard-wrapper">
<!--                      <div class="wizard-number">-->
<!--                        {{(this.contentTypeInstructions && this.contentTypeInstructions!='') ? 4 : 3}}-->
<!--                      </div>-->
                      <div class="wizard-label">
                        <div class="wizard-title">
<!--                          {{$t("MODULEIDEAVERSIONING.OVERVIEW")}}-->
                          {{ stepD }}
                        </div>
                      </div>
                    </div>
                  </div>

                  <!--Dettaglio-->
                  <div class="wizard-step col-12 col-md-3 col-lg-3 col-xl-3" data-wizard-type="step">
                    <div class="wizard-wrapper">
<!--                      <div class="wizard-number">-->
<!--                        {{(this.contentTypeInstructions && this.contentTypeInstructions!='') ? 5 : 4}}-->
<!--                      </div>-->
                      <div class="wizard-label">
                        <div class="wizard-title">
<!--                          {{$t("MODULE" + $handleContentTypeLabel(moduleContentType) + "VERSIONING.NARRAZIONE")}}-->
                          {{ stepE }}
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

              <!--: Wizard Body-->
              <div class="card card-custom card-shadowless rounded-top-0 pb-10">
                <div class="card-body p-0 m-0">
                  <div class="row justify-content-center">
                    <div class="col-xl-10">
                      <form class="form mt-10 p-10" id="kt_form">

                        <ConfirmCompleteLanguages v-if="this.languages.length > 1" :localizations="this.ideaLocalizations" :id="this.idea.id"></ConfirmCompleteLanguages>

                        <!--Istruzioni-->
                        <div class="pb-5" v-if="this.contentTypeInstructions && this.contentTypeInstructions!=''" data-wizard-type="step-content" data-wizard-state="current">
                          <div class="mb-10 font-weight-bold text-dark h2">
                            {{$t("MODULEIDEAVERSIONING.ISTRUZIONI")}}
                          </div>
                          <div class="form-group">
                            <div class="row col-12" v-html="this.contentTypeInstructions"></div>
                          </div>
                        </div>

                        <!--Sfide-->
                        <div class="pb-5" data-wizard-type="step-content" :data-wizard-state="(this.contentTypeInstructions && this.contentTypeInstructions!='') ? '' : 'current'" v-if="challenges && challenges.length > 0">
                          <div class="mb-10 font-weight-bold text-dark h2">
                            {{$t("MODULEIDEACREATE.CHALLENGE")}}
                          </div>
                          <div class="font-weight-bold text-dark h3">
                            {{$t("MODULEIDEAVERSIONING.SELEZIONATRA")}} <b>{{ challenges.length }}</b> {{$t("MODULEIDEAVERSIONING.CHALLENGE")}}
                          </div>
                          <p class="mb-10"> {{$t("MODULE" + $handleContentTypeLabel(moduleContentType) + "VERSIONING.SCEGLISFIDE")}}</p>
                          <div class="row" v-for="a in agendas" :key="a.id">
                            <div class="col-12"><h4>{{ a.name }}</h4></div>
                            <div class="col-12 col-lg-6" v-for="item in a.challenges" :key="item.id">
                              <label class="option" style="min-height: 150px;">

                                <!--Image-->
                                <span class="option-head">
                                  <span class="option-title">
                                    <img :src="item.icon ? item.icon.uri : $imageOptions.defaultLogoUrl" class="align-self-center" style="height: 100px; width: auto; max-width: 150px; border-radius: 0.42rem; object-fit: contain !important;"/>
                                  </span>
                                </span>

                                <!--Title-->
                                <span class="option-label ml-3">
                                  <span class="option-head">
                                    <span class="option-title w-100">
                                      <span class="option-focus w-100">{{ item.name }}</span>
                                    </span>
                                  </span>
                                </span>

                                <!--Check-->
                                <span class="option-control">
                                  <span class="checkbox">
                                    <input type="checkbox" class="ml-5" :value="item" v-model="$v.form.challenges.$model"/>
                                    <span></span>
                                  </span>
                                </span>

                              </label>
                            </div>
                            <div class="col-12"><hr /></div>
                          </div>
                        </div>

                        <!--Contesto-->
                        <div class="pb-5" data-wizard-type="step-content" v-if="scenarios && scenarios.length > 0 || displayViewMap">
                          <div class="font-weight-bold text-dark h2">
                            {{$t("MODULEIDEAVERSIONING.MAPPEEINFO")}}
                            <!--  {{ $v.form.scenarios.$model }}-->
                          </div>
                          <!-- se content-type == tattiche, ".selezionamappa non si deve vedere -->
                          <p v-if="$doNOTShowIfTactic(moduleContentType)" class="mb-10 "> {{$t("MODULE" + $handleContentTypeLabel(moduleContentType) + "VERSIONING.SELEZIONAMAPPA")}}</p>
                          <div v-else class="mb-10"></div>


                          <!-- <div class="font-weight-bold text-dark h3 mt-5">
                            {{$t("MODULEIDEAVERSIONING.ABBINACONTESTO")}}
                          </div> -->
                          <div class="form-group mt-3">
                            <div class="row d-flex flex-row" v-if="scenarios && scenarios.length > 0">
                              <div class="col-12 col-lg-6 align-items-stretch" v-for="item in scenarios" :key="item.id">
                                <label class="option" style="min-height: 120px;">

                                  <!--Image-->
                                  <span class="option-head">
                                    <span class="option-title">
                                      <img :src="item.background ? item.background.uri : $imageOptions.defaultLogoUrl" class="align-self-center" style="height: 100px; width: auto; max-width: 150px; border-radius: 0.42rem; object-fit: contain !important;"/>
                                    </span>
                                  </span>

                                  <!--Title-->
                                  <span class="option-label ml-3">
                                    <span class="option-head">
                                      <span class="option-title w-100">
                                        <span class="option-focus w-100">{{ item.title }}</span>
                                      </span>
                                    </span>
                                  </span>

                                  <!--Check-->
                                  <span class="option-control" style="margin-top: -5px;">
                                    <span class="checkbox">
                                      <input type="checkbox" class="ml-5" :value="item" v-model="$v.form.scenarios.$model"/>
                                      <span></span>
                                    </span>
                                  </span>

                                </label>
                              </div>
                            </div>
                          </div>

                          <div class="row d-flex flex row" v-if="displayViewMap">
                            <div style="width: 100%">

                              <gmap-autocomplete
                                  :value="$v.form.address.$model"
                                  v-bind:placeholder="$t('MODULEIDEAVERSIONING.PH3')"
                                  @place_changed="setPlace"
                                  @change="setPlace"
                                  :select-first-on-enter="true"
                                  class="form-control form-control-solid form-control-lg"
                                  style="width: 100%; margin-bottom: 20px;"
                              >
                              </gmap-autocomplete>
                            </div>
                            <Gmap-Map
                                :center="myCoordinates"
                                :zoom="zoom"
                                ref="mapRef"
                                :options="{
                                zoomControl: true,
                                mapTypeControl: false,
                                scaleControl: false,
                                streetViewControl: false,
                                rotateControl: false,
                                fullscreenControl: false,
                                disableDefaultUi: false
                              }"
                                @dragend="handleDrag"
                                style="width: 100%; height: 500px; "
                            >

                              <gmap-marker v-if="idea.centroid && ! this.place" :position="marker.position"></gmap-marker>
                              <gmap-marker v-if="this.place" label="" :position="{
                                lat: this.place.geometry.location.lat(),
                                lng: this.place.geometry.location.lng(),
                              }">
                              </gmap-marker>
                            </Gmap-Map>
                          </div>
                        </div>

                        <!--Descrizione-->
                        <div class="pb-5" data-wizard-type="step-content">
                          <div class="mb-10 font-weight-bold text-dark h2">
                            {{$t("MODULEIDEACREATE.OVERVIEW")}}
                          </div>

                          <div class="form-group">
                            <label class="font-weight-bolder">{{$t("MODULEIDEAVERSIONING.TITOLO")}} <b class="text-danger">*</b></label>

                            <FormControlLanguages ref="title" @changeLanguage="getLocValue('title', $event)"></FormControlLanguages>

                            <b-form-input
                                type="text"
                                class="form-control-solid"
                                size="lg"
                                name="title"
                                :placeholder="$t('MODULEIDEACOMMONS.PH0')"
                                v-model="$v.form.title.$model"
                                :state="validateState('title')"
                                @change="setLocValue('title', $event)"
                                :maxlength="$ideaForm.title.maxLength"
                            ></b-form-input>

                            <div class="form-text text-muted pt-2">
                              {{$t("MODULEIDEAVERSIONING.AGGIORNATITOLO")}}
                            </div>
                            <div v-if="isMaxLengthErrorVisible('title')"
                                 :class="[
                                     { 'text-danger': hasReachTheLimit('title'),
                                     'text-muted': !hasReachTheLimit('title')},
                                     'form-text']">
                              {{ $t("MODULEIDEACOMMONS.MAXLENGTHPART1") }}
                              <span class="font-size-lg font-weight-bold">{{ howManyCharactersLleft('title') }}</span>
                              {{ $t("MODULEIDEACOMMONS.MAXLENGTHPART2") }}
                            </div>
                          </div>
                          <div class="form-group">
                            <label class="font-weight-bolder">{{$t("MODULEIDEAVERSIONING.DESCRIZIONE")}} <b class="text-danger">*</b></label>

                            <FormControlLanguages ref="description" @changeLanguage="getLocValue('description', $event)"></FormControlLanguages>

                            <!-- <vue-editor
                                id="edit"
                                :editor-toolbar="customToolbar"
                                :editor-options="{ modules: { toolbar: false }}"
                                v-bind:placeholder="$t('MODULEIDEAVERSIONING.PH1')"
                                :tag="'textarea'"
                                v-model="$v.form.description.$model"
                                :state="validateState('description')"
                                row="10"
                                @input="setLocValue('description', $event)"
                                ref="editorDescription"
                                @text-change="onTextChange"
                            >
                            </vue-editor> -->

                            <b-form-textarea
                              id="edit"
                              v-model="$v.form.description.$model"
                              :placeholder="$t('MODULEIDEAVERSIONING.PH1')"
                              rows="10"               
                              :state="validateState('description')"  
                              @change="setLocValue('description', $event)"    
                            ></b-form-textarea>

                            <div class="form-text text-muted pt-2">
                              {{$t("MODULEIDEAVERSIONING.DESCRIVI")}}
                            </div>
                            <div v-if="isMaxLengthErrorVisible('description')"
                                 :class="[
                                     { 'text-danger': hasReachTheLimit('description'),
                                     'text-muted': !hasReachTheLimit('description')},
                                     'form-text']">
                              {{ $t("MODULEIDEACOMMONS.MAXLENGTHPART1") }}
                              <span class="font-size-lg font-weight-bold">{{ howManyCharactersLleft('description') }}</span>
                              {{ $t("MODULEIDEACOMMONS.MAXLENGTHPART2") }}
                            </div>
                          </div>
                          <div class="form-group" v-if="$showElement(moduleContentType)">
                            <label class="font-weight-bolder"> {{$t("MODULEIDEAVERSIONING.COSTO")}}</label>
                            <input
                                type="number"
                                min="0" max="1000000000"
                                :class="['form-control form-control-solid form-control-lg', $v.form.budgetMin.$dirty && ($v.form.budgetMin.$error || hasForbiddenCharacters ) ? 'is-invalid' : '']"
                                name="budgetMin"
                                placeholder="€"
                                v-model="$v.form.budgetMin.$model"
                                :state="validateState('budgetMin')"
                                @wheel="e => e.target.blur()"
                                @keyup="getBudgetMinValue"
                            />
                            <div class="form-text text-muted pt-2">
                              {{$t("MODULEIDEAVERSIONING.COSTOIPOTIZZATO")}}
                            </div>
                          </div>

                          <div v-if="idea.logo" class="mb-5">
                            <label class="font-weight-bolder lead">{{$t("MODULEIDEAVERSIONING.LOGO")}}</label>
                            <div class="row">
                              <div class="col-3">
                                <img
                                    :src="getLogo"
                                    class="w-100 mb-3"
                                />
                              </div>
                            </div>
                          </div>

                          <div class="form-group">
                            <label
                                class="font-weight-bolder"
                                v-html="(idea.logo) ? 'Aggiorna logo' : 'Inserisci logo'"
                            ></label
                            >
                            <vue-dropzone-4
                                ref="myVueDropzone4"
                                id="dropzone4"
                                :options="dropzoneOptions4"
                                :useCustomSlot="true"
                                @vdropzone-success="successUploadLogo"
                                @vdropzone-removed-file="removeUploadedLogo"
                            >
                              <div class="dropzone-custom-content">
                                <h3 class="dropzone-custom-title">{{$t("MODULEIDEAVERSIONING.TRASCINA")}} </h3>
                                <div class="subtitle">{{$t("MODULEIDEAVERSIONING.FILEACCETTATI")}}  </div>
                              </div>
                            </vue-dropzone-4>
                          </div>

                          <!--<div v-if="youtubeLink">
                            <h2>{{$t("MODULEIDEAVERSIONING.VIDEO")}}</h2>
                            <div class="row my-8">
                              <div class="font-weight-bolder font-size-lg px-5" v-html="youtubeLink">
                              </div>
                            </div>
                          </div>-->

                          <div v-if="youtubeLink">
                            <h2>{{$t("MODULEIDEAVERSIONING.VIDEO")}}</h2>
                            <div class="row my-8">
                              <div class="font-weight-bolder font-size-lg px-5">
                                <iframe style="width: 100%; height: 350px;" :src="youtubeLink" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                              </div>
                            </div>
                          </div>

                          <div class="form-group">
                            <label class="font-weight-bolder">{{$t("MODULEIDEAVERSIONING.LINKVIDEO")}}</label>
                            <input
                                type="text"
                                class="form-control form-control-solid form-control-lg"
                                name="youtubeLink"
                                v-bind:placeholder="$t('MODULEIDEAVERSIONING.PH2')"
                                v-model="$v.form.youtubeLink.$model"
                            />
                            <span class="form-text text-muted"
                            >{{$t("MODULEIDEAVERSIONING.VIDEOHINT")}}</span
                            >
                          </div>

                          <!--                          #qui contentSubtype -->
                          <div class="form-group" v-if="this.$showIfService(moduleContentType)">
                            <label class="font-weight-bolder"
                            >{{$t("MODULEIDEACOMMONS.SOTTOCATEGORIA")}}</label
                            >
                            <select
                                class="form-control form-control-solid form-control-lg"
                                name="contentSubtype"
                                v-model="$v.form.contentSubtype.$model"
                            >
                              <option :value="null">{{$t('MODULEIDEACOMMONS.PH5')}}</option>
                              <option v-for="subtype in contentSubtypes" :value="subtype.id" :key="subtype.id">{{ subtype.name }}</option>
                            </select>
                            <div class="form-text text-muted pt-2">
                              {{$t("MODULEIDEACOMMONS.SELEZIONASOTTOCATEGORIA")}}
                            </div>
                          </div>
                          <!--                          /contentSubtype -->

                          <div v-if="images.length > 0" class="mb-5">
                            <label class="font-weight-bolder lead">{{$t("MODULEIDEAVERSIONING.IMMAGINI")}}</label>
                            <div class="row">
                              <div class="col-3" v-for="(item, index) in images" :key="index">
                                <div class="card card-custom overlay">
                                  <div class="card-body p-0">
                                    <div class="overlay-wrapper" style="min-height: 150px; max-height: 150px; overflow: hidden;">
                                      <img :src="item.uri" alt="" class="w-100 rounded" />
                                    </div>
                                    <div class="overlay-layer m-5 rounded flex-center">
                                      <div class="d-flex flex-column flex-center">
                                        <a :href="item.uri" target="_blank" class="label label-info label-xl label-inline mb-1">{{$t("MODULEIDEAVERSIONING.MOSTRA")}}</a>
                                        <span class="label label-danger label-xl label-inline mb-1"
                                              @click="delResources($event, item, images)">{{$t("MODULEIDEAVERSIONING.CANCELLA")}}</span>
                                        <span class="font-weight-bolder text-white">{{ item.name }}</span>

                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="form-group">
                            <label class="font-weight-bolder"
                            >{{$t("MODULEIDEAVERSIONING.AGGIUNGIALTREIMMAGINI")}}</label
                            >
                            <vue-dropzone-3
                                ref="myVueDropzone3"
                                id="dropzone3"
                                :options="dropzoneOptions3"
                                :useCustomSlot="true"
                                @vdropzone-success="successUpload"
                                @vdropzone-removed-file="removeUploaded"
                            >
                              <div class="dropzone-custom-content">
                                <h3 class="dropzone-custom-title">{{$t("MODULEIDEAVERSIONING.TRASCINAIMMAGINI")}}</h3>
                                <div class="subtitle">{{$t("MODULEIDEAVERSIONING.FILEACCETTATIIMMAGINI")}} </div>
                              </div>
                            </vue-dropzone-3>
                          </div>

                          <div v-if="attaches.length > 0" class="mb-5">
                            <label class="font-weight-bolder lead">{{$t("MODULEIDEAVERSIONING.DOCTECNICA")}}</label>
                            <b-list-group>
                              <b-list-group-item
                                  class="d-flex justify-content-between align-items-center"
                                  v-for="(item, index) in attaches" :key="index"
                              >
                                <a :href="item.uri">{{ item.title }}</a>
                                <div>
                                  <b-badge variant="secondary" style="cursor: pointer" class="mr-2" pill @click="editResources($event, item, attaches)">{{$t("MODULEIDEAVERSIONING.MODIFICA")}}</b-badge>
                                  <b-badge variant="primary" style="cursor: pointer"  pill @click="delResources($event, item, attaches)">{{$t("MODULEIDEAVERSIONING.CANCELLA")}}</b-badge>
                                </div>
                              </b-list-group-item>
                            </b-list-group>
                          </div>
                          <div class="form-group" >
                            <label class="font-weight-bolder"
                            >{{$t("MODULEIDEAVERSIONING.DOCALLEGATI")}}</label
                            >
                            <vue-dropzone-1
                                ref="myVueDropzone1"
                                id="dropzone1"
                                @vdropzone-success="successUpload"
                                @vdropzone-removed-file="removeUploaded"
                                :options="dropzoneOptions1"
                                :useCustomSlot="true"
                            >
                              <div class="dropzone-custom-content">
                                <h3 class="dropzone-custom-title">{{$t("MODULEIDEAVERSIONING.TRASCINADOC")}}</h3>
                                <div class="subtitle">{{$t("MODULEIDEAVERSIONING.FILEACCETTATIDOC")}}</div>
                              </div>
                              <div class="form-text text-muted pt-2">
                                {{$t("MODULE" + $handleContentTypeLabel(moduleContentType) + "VERSIONING.ALTROMATERIALE")}}
                              </div>
                            </vue-dropzone-1>

                          </div>

                          <!--                          #qui url -->
                          <div class="form-group">
                            <label class="font-weight-bolder"
                            >{{$t("MODULE" + $handleContentTypeLabel(moduleContentType) + "VERSIONING.URLSITO")}} </label
                            >
                            <input
                                type="text"
                                class="form-control form-control-solid form-control-lg"
                                name="url"
                                v-bind:placeholder="$t('MODULEIDEAVERSIONING.PH4')"
                                v-model="$v.form.url.$model"
                            />
                            <!-- v-model="$v.form.youtubeLink.$model" -->
                            <div class="form-text text-muted pt-2">
                              {{$t("MODULEIDEACOMMONS.AGGIUNGIURL")}}
                            </div>
                          </div>
                          <!--                          /url -->



                        </div>

                        <!--Dettaglio-->
                        <div class="pb-5" data-wizard-type="step-content">
                          <div class="mb-10 font-weight-bold text-dark h2">
                            {{$t("MODULE" + $handleContentTypeLabel(moduleContentType) + "VERSIONING.NARRAZIONE")}}
                          </div>
                          <div class="form-group">

                            <FormControlLanguages ref="narration" @changeLanguage="getLocValue('narration', $event)"></FormControlLanguages>

                            <vue-editor
                                id="narration"
                                :editor-toolbar="customToolbar"
                                :tag="'textarea'"
                                v-model="$v.form.narration.$model"
                                row="30"
                                @input="setLocValue('narration', $event)"
                            >
                            </vue-editor>
                            <div class="form-text text-muted pt-2">
                              {{$t("MODULE" + $handleContentTypeLabel(moduleContentType) + "VERSIONING.NARRA")}}
                            </div>
                          </div>
                        </div>

                        <!--Toolbar-->
                        <div class="d-flex justify-content-between border-top align-items-center">

                          <div class="">
                            <button
                                class="btn btn-default font-weight-bold text-uppercase btn-lg pl-3 pr-2"
                                data-wizard-type="action-prev"
                            >
                              <i class="icon flaticon2-back icon-md"></i>
                            </button>
                          </div>

                          <div class="mt-5">
                            <router-link
                                :to="{ name: 'processModuleIdeaDetail', params: { process_id: process_id, phase_id: phase_id, module_id: module_id, id: idea.id} }"
                                class="btn btn-secondary btn-lg font-weight-bold text-uppercase mr-2"
                            >
                              {{$t("MODULEIDEAVERSIONING.ANNULLA")}}
                            </router-link>

                            <button
                                v-on:click="submitDraft"
                                class="btn btn-primary btn-lg font-weight-bolder text-uppercase mr-2"
                                data-wizard-type="action-submit"
                            >
                              {{$t("MODULEIDEAVERSIONING.BOZZA")}}
                            </button>
                            <button
                                v-on:click="submitPublic"
                                class="btn btn-primary btn-lg font-weight-bolder text-uppercase"
                                data-wizard-type="action-submit"
                            >
                              {{$t("MODULEIDEAVERSIONING.PUBBLICA")}}
                            </button>
                            <button
                                class="btn btn-primary btn-lg font-weight-bold text-uppercase"
                                data-wizard-type="action-next"
                            >
                              {{$t("MODULEIDEAVERSIONING.AVANTI")}}
                            </button>
                          </div>
                        </div>

                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="editResourcesModal" ref="editResourcesModal" v-bind:title="$t('MODULEIDEAVERSIONING.EDITRESOURCESMODALTITLE')" ok-only centered
       @show="editResourcesResetModal"
       @hidden="editResourcesResetModal"
       @ok="editResourcesHandleOk"
    >
      <form ref="formEditResources" @submit.stop.prevent="editResourcesHandleSubmit">
        <b-form-group
            :label="$t('MODULEIDEAVERSIONING.TITLE')"
            label-for="name-input"
            :invalid-feedback="$t('MODULEIDEAVERSIONING.ERR1')"
            :state="editResourcesNameState"
        >
          <b-form-input
              id="name-input"
              v-model="editResourcesName"
              :state="editResourcesNameState"
              required
          ></b-form-input>
        </b-form-group>
      </form>
    </b-modal>
  </div>
</template>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-4.scss";

/* togliere cursori da inpute type==number*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield !important;
}
</style>

<script>
/* eslint-disable no-unused-vars */

import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Phaseline from "@/view/layout/common/Phaseline";
import KTWizard from "@/assets/js/components/wizard";
import KTUtil from "@/assets/js/components/util";
import ApiService from "@/core/services/api.service";
import { validationMixin } from "vuelidate";
import {minLength, required, requiredIf} from "vuelidate/lib/validators";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import JwtService from "@/core/services/jwt.service";
import Swal from "sweetalert2";
import { ideaMixin } from "@/mixins/idea";
import { processMixin } from "@/mixins/process";
import { VueEditor } from "vue2-editor";
import {gmapApi} from "gmap-vue";
import FormControlLanguages from "@/view/layout/common/FormControlLanguages.vue";
import i18nService from "@/core/services/i18n.service";
import GoogleMaps from "@/core/services/googlemaps.service.js";
import ConfirmCompleteLanguages from "@/view/layout/common/ConfirmCompleteLanguages.vue";

export default {
  name: "ModuleIdeaVersioning",
  mixins: [ideaMixin, processMixin, validationMixin],
  components: {
    Phaseline,
    VueEditor,
    vueDropzone1: vue2Dropzone,
    vueDropzone3: vue2Dropzone,
    vueDropzone4: vue2Dropzone,
    FormControlLanguages,
    ConfirmCompleteLanguages
  },
  data() {
    return {
      lang: 'it',
      contentSubtypes: [],
      stepA: 1,
      stepB: 2,
      stepC: 3,
      stepD: 4,
      stepE: 5,

      moduleContentType: null,

      limitDescription: 600, // #errore maxLength

      hasForbiddenCharacters: null,
      budgetMinValue: null,

      istruzioniValue: null,
      challengesValue: null,
      scenariosValue: null,
      overviewValue: null,
      narrazioneValue: null,

      displayViewMap: false,
      hasProcessManyPhases: null,
      hasPhaseManyModules: null,

      maxVersionsPerContent: 0,
      contentTypeInstructions: '',
      versionGroup: 0,
      versionNumber: 0,
      ideaStatus: 1,
      editResourcesName: '',
      editResourcesNameState: null,
      editResourcesId: null,
      map_language: i18nService.getActiveLanguage(),
      languages: [],
      localizations: [],
      ideaLocalizations: [],
      id: this.$route.params.id,
      process_id: this.$route.params.process_id,
      phase_id: this.$route.params.phase_id,
      module_id: this.$route.params.module_id,
      percentage: 0,

      process: [],
      phase: [],
      module: [],
      idea: [],
      challenges: [],
      agendas: [],
      scenarios: [],
      resources: [],
      resources_added: [],

      logo_original: [],
      video: [],
      images: [],
      attaches: [],

      youtubeLink: "",
      youtubeLinkId: 0,

      form: {
        contentSubtype: null,
        url: "",
        title: "",
        description: "",
        budgetMin: "",
        scenarios: [],
        challenges: [],
        youtubeLink: "",
        address: "",
        narration: "",
        // stato: ""
      },

      customToolbar: [
        ["bold", "italic", "underline", "link"],
        [{ list: "ordered" }, { list: "bullet" }]
      ],

      dropzoneOptions1: {
        url: this.$baseUrl + this.$apiResourceOpen + "/resources/doc/idea_content",
        // url: "http://18.197.92.87:8080/api/v1/resources/doc/idea_content",
        thumbnailWidth: 150,
        maxFilesize: 20,
        addRemoveLinks: true,
        acceptedFiles: ".doc, .docx, .xls, .xlsx, .ppt, .pptx, .pdf",
        headers: {
          Authorization: `Bearer ${JwtService.getToken()}`
        },
        params: {
          metadata: new Blob(
              [
                JSON.stringify({
                  title: "",
                  description: ""
                })
              ],
              {
                type: "application/json"
              }
          )
        }
      },
      dropzoneOptions3: {
        url: this.$baseUrl + this.$apiResourceOpen + "/resources/image/idea_content/not_applicable",
        // url: "http://18.197.92.87:8080/api/v1/resources/image/idea_content/not_applicable",

        thumbnailWidth: 150,
        maxFilesize: 20,
        addRemoveLinks: true,
        acceptedFiles: ".jpg, .jpeg, .png, .gif",
        headers: {
          Authorization: `Bearer ${JwtService.getToken()}`
        },
        params: {
          metadata: new Blob(
              [
                JSON.stringify({
                  title: "",
                  description: ""
                })
              ],
              {
                type: "application/json"
              }
          )
        }
      },
      dropzoneOptions4: {
        url: this.$baseUrl + this.$apiResourceOpen + "/resources/image/not_applicable/logo",
        thumbnailWidth: 150,
        maxFiles: 1,
        maxFilesize: 20,
        addRemoveLinks: true,
        acceptedFiles: '.jpg, .jpeg, .png, .gif',
        headers: {
          "Authorization": `Bearer ${JwtService.getToken()}`,
        },
        params: {
          "metadata": new Blob(
              [JSON.stringify(
                  {
                    "title": "",
                    "description": ""
                  }, )], {
                type: "application/json"
              })
        }
      },

      /*
      |--------------------------------------------------------------------------
      | MAPS
      |--------------------------------------------------------------------------
      */
      map: null,
      myCoordinates: {
        lat: 0,
        lng: 0
      },
      zoom: 14,
      markers: {},
      place: null,
      ideaAddress: "",
      ideaPoint: "",
    };
  },
  validations() {
    return {
      form: {
        contentSubtype: {
          requiredIfFunction: requiredIf(this.requiredIfService),
          // required,
        },
        url: {

        },
        title: {
          required,
          minLength: minLength(3),
          // maxLenght: maxLength(60),
        },
        description: {
          required,
          // maxLenght: maxLength(600),
        },
        budgetMin: {
          // required
        },
        scenarios: {
          // required
        },
        challenges: {
          // required
        },
        youtubeLink: {
          // required
        },
        address: {
          // required
        },
        narration: {
          //   required
        }
      }
    }
  },
  computed: {
    ...mapGetters(["currentUser"]),
    getLogo() {
      return  (this.idea.logo) ? this.idea.logo.uri : '/assets/media/empaville/btn-phase-01.png';
    },

    /*
    |--------------------------------------------------------------------------
    | GEOMAPS
    |--------------------------------------------------------------------------
    */
    mapCoordinates() {
      if ( ! this.map) {
        return this.$defaultCoordinates;
      }
      return {
        lat: this.map.getCenter().lat().toFixed(4),
        lng: this.map.getCenter().lng().toFixed(4),
      }
    },
    google: gmapApi,
  },
  async mounted() {
    this.$isLoading(true);

    this.lang = i18nService.getActiveLanguage();
    console.debug('lang: ', this.lang);

    this.process = await this.getProcess(this.process_id)
    this.percentage = this.getPercentage(this.process);
    this.phase = this.getCurrentPhase(this.process, this.phase_id);
    this.module = await this.getCurrentModule(this.process, this.module_id);
    this.challenges =  this.module.challenges;
    this.scenarios = this.process.scenarios;
    this.moduleContentType = this.module?.contentType;

    const agendaIds = this.challenges.map((x) => x.agendaId).filter((value, index, array) => array.indexOf(value) === index);

    for(let aId of agendaIds) {
      var endpoint = this.generateUrl(`agendas/${aId}`, true);
      //generateUrl is done
      ApiService.get(this.$apiResource, endpoint)
        .then(res => {  
          this.agendas.push({
            id: res.data.id,
            name: res.data.name || "",
            challenges: this.challenges.filter((x) => x.agendaId == res.data.id)
          })
        })     
    }

    this.hasProcessManyPhases = this.process.countProcessPhases > 1;
    this.hasPhaseManyModules = this.phase.countProcess2Modules > 1;

    this.contentTypeInstructions = (this.module.localizations[0] && this.module.localizations[0].contentTypeInstructions) ? this.module.localizations[0].contentTypeInstructions : '';

    this.languages = this.getLanguages();

    this.languages.forEach(lang => {
      if(!this.localizations.find(loc => loc.locale == lang.locale)) {
        this.localizations[lang.locale] = {'locale': lang.locale};
      }
    })

    if(this.module) {

      this.maxVersionsPerContent = this.module.maxVersionsPerContent || 0;
    }

    this.getCurrentIdea( this.id).then(res => {
      this.idea = res.data;
      console.log("current idea:", this.idea)

      this.form.contentSubtype = this.idea?.contentSubtype?.id

      if(this.idea.localizations.length > 0) {
        this.ideaLocalizations = this.idea.localizations;

        this.idea.localizations.forEach(lang => {

          this.ideaLocalizations[this.ideaLocalizations.findIndex(loc => loc.locale == lang.locale)].recordNative = false;
          this.localizations[lang.locale] = lang;
        })
      }

      this.marker = {
        position: this.idea.centroid ? this.pointToCoordinates(this.idea.centroid) : {},
      }
      this.ideaAddress = this.idea.address;
      this.ideaStatus = this.idea.ideaStatus.id;

      if ( this.idea.centroid ) {
        this.ideaPoint = this.idea.centroid;
        this.myCoordinates = this.pointToCoordinates(this.idea.centroid);
      } else if ( !this.idea.centroid && this.process.cities[0] ) {
        let cityPoint = this.process.cities[0].centroid;
        this.myCoordinates = this.pointToCoordinates(cityPoint);
      } else if ( !this.idea.centroid && this.process.cities.length <1 && localStorage.center) {
        this.myCoordinates = JSON.parse(localStorage.center);
      } else {
        this.$getLocation({})
            .then(coordinates => {
              this.myCoordinates = coordinates;
            })
            .catch(() => {
              this.myCoordinates = this.$defaultCoordinates;
            })
      }

      this.voto4 =
          res.data.type4VotedByAuthUser > 0
              ? res.data.type4VotedByAuthUser
              : "";
      this.setResources();
      this.setIdeaValues();
      this.$isLoading(false);

      this.alertVersion();
    });

    if (localStorage.zoom) {
      this.zoom = parseInt(localStorage.zoom);
    }

    if(this.process?.cities?.length) {

      this.displayViewMap = true;
      this.$refs?.mapRef?.$mapPromise?.then(map => this.map = map);
    }

    // contentSubtype
    this.contentSubtypes = this.getContentSubtypes(this.lang, this.moduleContentType).then(res => {
      this.contentSubtypes = res.data
      if(this.contentSubtypes == null || this.contentSubtypes == [] || this.contentSubtypes == '') {
        this.form.contentSubtype = true
      }
      console.debug('#tsc: ', this.contentSubtypes);
    })

    // this.istruzioniValue = this.contentTypeInstructions && this.contentTypeInstructions!='' ? 1 : 0;
    // this.challengesValue = this.challenges && this.challenges.length > 0 ? 1 : 0;
    // this.scenariosValue = ((this.scenarios && this.scenarios.length) || this.displayViewMap) > 0 ? 1 : 0;
    // this.overviewValue = this.istruzioniValue + this.challengesValue + this.scenariosValue + 1;
    // this.narrazioneValue = this.overviewValue + 1;

    // nuovo
    this.stepA = this.contentTypeInstructions && this.contentTypeInstructions!='' ? 1 : 0;
    this.stepB = this.challenges && this.challenges.length > 0 ? 1 + this.stepA : this.stepA;
    this.stepC = ((this.scenarios && this.scenarios.length) > 0 || this.displayViewMap) ? 1 + this.stepB : this.stepB;
    this.stepD = 1 + this.stepC;
    this.stepE = 1 + this.stepD;

    //  breadcrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("BCPROCESSI.ELENCOPROCESSI"), route: "/process-list" },
      { title: this.process.title, route: this.hasProcessManyPhases ?  "/process-detail/" + this.process_id : "disabled" },
      // { title: this.process.localizations[0].title, route: "/process-detail/" + this.process_id },
      { title: this.phase.title, route: this.hasPhaseManyModules ? `/process-phase/${this.phase_id}/${this.process_id}` : 'disabled'},
      { title: this.module.title, route: "/idea-list/" + this.module_id + "/" + this.process_id + "/" + this.phase_id },
      { title: this.$t("MODULE" + this.$handleContentTypeLabel(this.moduleContentType) + "VERSIONING.MODIFICAIDEA") }
    ]);

    // this.$store.dispatch(SET_BREADCRUMB, [
    //   // //{ title: "Elenco Processi", route: "/process-list" },
    //   // { title: this.$t("MODULEIDEAVERSIONING.PROCESSO"), route: "/process-detail/" + this.process_id },
    //   // {
    //   //   title: this.$t("MODULE" + this.$handleContentTypeLabel(this.moduleContentType) + "VERSIONING.ELENCOIDEE"),
    //   //   route:
    //   //       "/idea-list/" +
    //   //       this.module_id +
    //   //       "/" +
    //   //       this.process_id +
    //   //       "/" +
    //   //       this.phase_id
    //   // },
    //
    //     { title: this.$t("BCPROCESSI.ELENCOPROCESSI"), route: "/process-list" },
    //     { title: this.process.title, route: "/process-detail/" + this.process_id },
    //     { title: this.phase.title, route: `/process-phase/${this.phase_id}/${this.process_id}`},
    //     { title: this.module.title, route: "/idea-list/" + this.module_id + "/" + this.process_id + "/" + this.phase_id },
    //     { title: this.$t("MODULE" + this.$handleContentTypeLabel(this.moduleContentType) + "VERSIONING.MODIFICAIDEA") }
    // ]);

    setTimeout(() => {
      // Initialize form wizard
      const wizard = new KTWizard("kt_wizard_v4", {
        startStep: 1, // initial active step number
        clickableSteps: true // allow step clicking
      });

      // Validation before going to next page
      wizard.on("beforeNext", (wizardObj) =>{
        if (wizardObj.getStep() === this.stepD) {

        if(this.localizations != null) {
          for (const lang of this.languages) {

            if(this.form.url !== this.idea.url) {
              this.localizations[lang.locale].url = this.form.url
            }

            if(this.localizations[lang.locale] != null) {

              if(this.localizations[lang.locale].title == null || this.localizations[lang.locale].title == '' || this.localizations[lang.locale].description == null || this.localizations[lang.locale].description == '') {
                Swal.fire({
                  title: this.$t("MODULEIDEAVERSIONING.ALERT1TITLE"),
                  text:this.$t("MODULEIDEAVERSIONING.ALERT1"),
                  icon: "error",
                  confirmButtonClass: "btn btn-secondary"
                }).then(() => {
                  setTimeout(() => {
                    KTUtil.scrollTop();
                  }, 500);
                });

                wizardObj.stop();
                return;
              }
            }
          }
        }

          this.$v.form.$touch();
          
          if (this.$v.form.$anyError || this.hasForbiddenCharacters) {
            Swal.fire({
              title: this.$t("MODULEIDEAVERSIONING.ALERT1TITLE"),
              text: (this.$v.form.title.$anyError || this.$v.form.description.$anyError || this.$v.form.contentSubtype.$anyError) ? this.$t("MODULE" + this.$handleContentTypeLabel(this.moduleContentType) + "VERSIONING.ALERT1") : this.$t(("MODULE" + this.$handleContentTypeLabel(this.moduleContentType) + "VERSIONING.ALERT5")), // todo: aggiungere chiavi module-related
              icon: "error",
              confirmButtonClass: "btn btn-secondary"
            }).then(() => {
              setTimeout(() => {
                KTUtil.scrollTop();
              }, 500);
            })

            wizardObj.stop();
          }  
        }
      });

      // Change event
      wizard.on("change", function(/*wizardObj*/) {
        setTimeout(() => {
          KTUtil.scrollTop();
        }, 500);
      });
    }, 100)


    //this.$refs.mapRef.$mapPromise.then(map => this.map = map);
  },
  async created() {
    //   this.$isLoading(true);
    //   this.process = this.getProcess(this.process_id)
    //   this.percentage = this.getPercentage(this.process);
    //   this.phase = this.getCurrentPhase(this.process, this.phase_id);
    //   this.module = this.getCurrentModule(this.process, this.module_id);
    //   this.challenges =  this.module.challenges;
    //   this.scenarios = this.process.scenarios;
    // this.moduleContentType = this.module?.contentType;
    //
    // this.hasProcessManyPhases = this.process.countProcessPhases > 1;
    // this.hasPhaseManyModules = this.phase.countProcess2Modules > 1;
    //
    //   this.contentTypeInstructions = (this.module.localizations[0] && this.module.localizations[0].contentTypeInstructions) ? this.module.localizations[0].contentTypeInstructions : '';
    //
    //   this.languages = this.getLanguages();
    //
    //   this.languages.forEach(lang => {
    //     if(!this.localizations.find(loc => loc.locale == lang.locale)) {
    //       this.localizations[lang.locale] = {'locale': lang.locale};
    //     }
    //   })
    //
    // if(this.module) {
    //
    //   this.maxVersionsPerContent = this.module.maxVersionsPerContent || 0;
    // }
    //
    // this.getCurrentIdea( this.id).then(res => {
    //     this.idea = res.data;
    //   console.log("current idea:", this.idea)
    //
    //     if(this.idea.localizations.length > 0) {
    //       this.ideaLocalizations = this.idea.localizations;
    //
    //       this.idea.localizations.forEach(lang => {
    //
    //         this.ideaLocalizations[this.ideaLocalizations.findIndex(loc => loc.locale == lang.locale)].recordNative = false;
    //         this.localizations[lang.locale] = lang;
    //       })
    //     }
    //
    //     this.marker = {
    //       position: this.idea.centroid ? this.pointToCoordinates(this.idea.centroid) : {},
    //     }
    //     this.ideaAddress = this.idea.address;
    //     this.ideaStatus = this.idea.ideaStatus.id;
    //
    //     if ( this.idea.centroid ) {
    //       this.ideaPoint = this.idea.centroid;
    //       this.myCoordinates = this.pointToCoordinates(this.idea.centroid);
    //     } else if ( !this.idea.centroid && this.process.cities[0] ) {
    //       let cityPoint = this.process.cities[0].centroid;
    //       this.myCoordinates = this.pointToCoordinates(cityPoint);
    //     } else if ( !this.idea.centroid && this.process.cities.length <1 && localStorage.center) {
    //       this.myCoordinates = JSON.parse(localStorage.center);
    //     } else {
    //       this.$getLocation({})
    //           .then(coordinates => {
    //             this.myCoordinates = coordinates;
    //           })
    //           .catch(() => {
    //             this.myCoordinates = this.$defaultCoordinates;
    //           })
    //     }
    //
    //     this.voto4 =
    //         res.data.type4VotedByAuthUser > 0
    //             ? res.data.type4VotedByAuthUser
    //             : "";
    //     this.setResources();
    //     this.setIdeaValues();
    //     this.$isLoading(false);
    //
    //     this.alertVersion();
    // });
    //
    // if (localStorage.zoom) {
    //   this.zoom = parseInt(localStorage.zoom);
    // }
    //
    // if(this.process?.cities?.length) {
    //
    //   this.displayViewMap = true;
    //   this.$refs?.mapRef?.$mapPromise?.then(map => this.map = map);
    // }
    //
    // // contentSubtype
    // this.contentSubtypes = this.getContentSubtypes(this.moduleContentType).then(res => {
    //   this.contentSubtypes = res.data
    //   if(this.contentSubtypes == null || this.contentSubtypes == [] || this.contentSubtypes == '') {
    //     this.form.contentSubtype = true
    //   }
    //   console.debug('#tsc: ', this.contentSubtypes);
    // })
    //
    // this.istruzioniValue = this.contentTypeInstructions && this.contentTypeInstructions!='' ? 1 : 0;
    // this.challengesValue = this.challenges && this.challenges.length > 0 ? 1 : 0;
    // this.scenariosValue = ((this.scenarios && this.scenarios.length) || this.displayViewMap) > 0 ? 1 : 0;
    // this.overviewValue = this.istruzioniValue + this.challengesValue + this.scenariosValue + 1;
    // this.narrazioneValue = this.overviewValue + 1;
  },
  methods: {
    requiredIfService() {
      return this.moduleContentType?.code === "SERVICE"
    },

    // #errore maxLength
    isMaxLengthErrorVisible(element) {
      if(element == 'description') {
        return this.$ideaForm[element].maxLength - document?.querySelector("#edit")?.innerText?.length <= this.$ideaForm[element].limitBeforeAlert;
      }
      return this.$ideaForm[element].maxLength - this.$v.form[element].$model.length <= this.$ideaForm[element].limitBeforeAlert;
    },

    hasReachTheLimit(element) {
      if(element == 'description') {
        return this.$ideaForm[element].maxLength - document?.querySelector("#edit")?.innerText?.length <= this.$ideaForm[element].limitBeforeAlert;
      }
      return this.$ideaForm[element].maxLength - this.$v.form[element].$model.length <= this.$ideaForm[element].limitBeforeAlert;
    },

    howManyCharactersLleft(element) {
      if(element == 'description') {
        return this.$ideaForm[element].maxLength - document?.querySelector("#edit")?.innerText?.length
      }
      return this.$ideaForm[element].maxLength - this.$v.form[element].$model.length
    },

    onTextChange() {
      const quill = this.$refs.editorDescription.quill
      const len = quill.getLength()
      if (len > this.limitDescription) {
        quill.deleteText(this.limitDescription, len);
      }
    },
    // /
    getBudgetMinValue(e) {
      console.log('getBudgetMinValue: ', e.key)
      e.key == "." || e.key == "," || e.key == "e"?
          this.budgetMinValue = e.key :
          this.budgetMinValue = e.target._value;

      console.log('budgetMinValue: ', this.budgetMinValue)

      if(this.budgetMinValue != null || this.budgetMinValue != '') this.checkForbiddenCharacters();
    },

    checkForbiddenCharacters() {
      console.debug('checkForbiddenCharacters: ', this.budgetMinValue);
      console.debug('checkForbiddenCharacters $model: ', this.$v.form.budgetMin.$model);
      // eslint-disable-next-line no-useless-escape
      if(/[,\.e]/.test(this.budgetMinValue)) {
        this.hasForbiddenCharacters = true;
      } else {
        this.hasForbiddenCharacters = false;
      }
      console.debug('hasForbiddenCharacters: ', this.hasForbiddenCharacters );
    },

    alertVersion(){

      if(this.maxVersionsPerContent > 0) {

        Swal.fire({
          title: this.$t("IDEADETAIL.CANVERSIONTITLE2", { count: this.maxVersionsPerContent }),
          html: this.$t((this.$handleContentTypeLabel(this.moduleContentType) + "DETAIL.CANVERSION2"), { n: this.versionNumber+1 }),
          icon: "info",
          confirmButtonClass: "btn btn-primary"
        });
      }
    },

    setCityPoint() {
      return this.process.cities[0] ? this.process.cities[0].centroid : "";
    },

    handleDrag() {
      let center = {
        lat: this.map.getCenter().lat(),
        lng: this.map.getCenter().lng(),
      };
      let zoom = this.map.getZoom();

      localStorage.center = JSON.stringify(center);
      localStorage.zoom = zoom;
    },

    setDescription(description) {
      this.description = description;
    },

    setPlace(place) {
      if(place.geometry && place.geometry != undefined) {

        this.place = place
        this.form.address = place.formatted_address;
        this.ideaAddress = place.formatted_address;
        this.ideaPoint = "POINT (" + place.geometry.location.lng() + " " + place.geometry.location.lat() + ")";
        this.map.panTo({lat: place.geometry.location.lat(), lng: place.geometry.location.lng()});

      } else {

        this.place = null;
        this.form.address = '';
        this.ideaAddress = '';
        this.ideaPoint = null;
        this.idea.centroid = null;
      }
    },

    usePlace() {
      if (this.place) {
        this.markers.push({
          position: {
            lat: this.place.geometry.location.lat(),
            lng: this.place.geometry.location.lng(),
          }
        })
        this.place = null;
      }
    },

    delResources(e, item, set) {
      e.preventDefault();

      // Rimuovo dal gruppo
      for( let i = 0; i < set.length; i++){
        if ( set[i].id === item.id) {
          set.splice(i, 1);
        }
      }

      // Rimuovo dalle risorse
      for( let i = 0; i < this.resources.length; i++){
        if ( this.resources[i].id === item.id) {
          this.resources.splice(i, 1);
        }
      }
    },

    editResourcescheckFormValidity() {
      const valid = this.$refs["formEditResources"].checkValidity();
      this.editResourcesNameState = valid;
      return valid;
    },

    editResourcesHandleSubmit() {
      if (!this.editResourcescheckFormValidity()) {
        return;
      }

      var i = this.idea.resources.findIndex(x=>x.id == this.editResourcesId);

      this.languages.forEach(lang => {
        this.idea.resources[i].localizations[this.idea.resources[i].localizations.findIndex(loc => loc.locale == lang.locale)].title = this.editResourcesName;
      })

      this.setResources();

      this.$nextTick(() => {
        this.$refs["editResourcesModal"].hide();
      })
    },

    editResourcesResetModal() {
      this.editResourcesName = '';
      this.editResourcesNameState = null;
      this.editResourcesId = null;
    },

    editResourcesHandleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.editResourcesHandleSubmit();
    },

    editResources(e, item) {
      e.preventDefault();
      this.$refs["editResourcesModal"].show();
      this.editResourcesName = item.title;
      this.editResourcesId = item.id;
    },

    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },

    submitPublic(e) {

      if(this.ideaLocalizations.filter(x => x.recordNative == true).length > 0 || this.languages.length === 1) {

        Swal.fire({
          title: this.$t("MODULE" + this.$handleContentTypeLabel(this.moduleContentType) + "VERSIONING.ALERT2TITLE"),
          text: this.$t("MODULEIDEAVERSIONING.ALERT4"),
          icon: "error",
          showCloseButton: true,
          showCancelButton: true,
          cancelButtonClass: "btn btn-primary text-uppercase",
          cancelButtonText: this.$t("MODULEIDEAVERSIONING.PUBBLICA"),
          confirmButtonClass: "btn btn-primary text-uppercase",
          confirmButtonText: this.$t("MODULEIDEAVERSIONING.BOZZA"),
        })
        .then((result) => {

          if (result.isConfirmed) {

            this.ideaStatus = 1;
            this.submit(e);

          } else if(result.isDismissed && result.dismiss == "cancel") {

            this.ideaStatus = 3;
            this.submit(e);
          }
        });
        e.preventDefault();
        return false;

      } else {

        Swal.fire({
          title: this.$t("MODULEIDEAVERSIONING.ALERT1TITLE"),
          text: this.$t("MODULE" + this.$handleContentTypeLabel(this.moduleContentType) + "VERSIONING.ALERT3"),
          icon: "error",
          confirmButtonClass: "btn btn-secondary"
        });
        e.preventDefault();
        return false;
      }
    },

    submitDraft(e) {
      this.ideaStatus = 1;
      this.submit(e);
    },

    async submit(e) {
      e.preventDefault();
      this.$isLoading(true);
      var is_correct = true;

      //Create localizations
      var put_localizations = [];
      var localizated_address = '';
      if(this.localizations != null) {

        for (const lang of this.languages) {

          if(this.form.url !== this.idea.url) {
            this.localizations[lang.locale].url = this.form.url
          }

          if(this.localizations[lang.locale] != null) {

            if(this.localizations[lang.locale].title != null && this.localizations[lang.locale].title != '' && this.localizations[lang.locale].description != null && this.localizations[lang.locale].description != '') {

              if(this.place != null && this.place.geometry.location.lng() && this.place.geometry.location.lat()) {

                //localizated_address = await GoogleMaps.get(this.place.geometry.location.lat(), this.place.geometry.location.lng(), lang.locale);

                localizated_address = await GoogleMaps.getByAddress(this.form.address, lang.locale);

                if(localizated_address && localizated_address != '') {
                  this.localizations[lang.locale].address = localizated_address;
                }

              } else if(this.form.address != '') {

                this.localizations[lang.locale].address = this.localizations[lang.locale].address || null;

              } else {

                this.localizations[lang.locale].address = null;
              }

              put_localizations.push(this.localizations[lang.locale]);

            } else {

              Swal.fire({
                title: this.$t("MODULEIDEAVERSIONING.ALERT1TITLE"),
                text:this.$t("MODULEIDEAVERSIONING.ALERT1"),
                icon: "error",
                confirmButtonClass: "btn btn-secondary"
              });
              is_correct = false;
              this.$isLoading(false);
              false;
            }
          }
        }
      }

      //is_correct = false;

      // Effettua la validazione
      this.$v.form.$touch();

      // Alert in caso di errori e blocco del salvataggio
      if (this.$v.form.$anyError || this.hasForbiddenCharacters) {
        Swal.fire({
          title: this.$t("MODULEIDEAVERSIONING.ALERT1TITLE"),
          text: (this.$v.form.title.$anyError || this.$v.form.description.$anyError || this.$v.form.contentSubtype.$anyError) ? this.$t("MODULE" + this.$handleContentTypeLabel(this.moduleContentType) + "VERSIONING.ALERT1") : this.$t(("MODULE" + this.$handleContentTypeLabel(this.moduleContentType) + "VERSIONING.ALERT5")), // todo: aggiungere chiavi module-related
          icon: "error",
          confirmButtonClass: "btn btn-secondary"
        });
        is_correct = false;
        this.$isLoading(false);
        return false;
      }

      if(is_correct) {

        if (this.youtubeLink != this.form.youtubeLink) {
          // console.log("Carico il link");
          if (this.form.youtubeLink) {
            // Carico la nuova risorsa e aggiorno l'array delle risorse
            await this.uploadLink();
            // console.log("Fine caricamento link");
          } else {
            // Cancello il video presente
            await this.deleteLink();
          }
        }

        // Salvo l'idea
        console.log("localizations:", put_localizations);
        console.log("Inizio salvataggio idea");
        let idea_id = await this.saveIdea(put_localizations);
        // console.log("Fine salvataggio idea");
        // console.log("idea_id: " + idea_id);

        Swal.fire({
          title: "",
          text: this.$t("MODULE" + this.$handleContentTypeLabel(this.moduleContentType) + "VERSIONING.ALERT2"),
          icon: "success",
          confirmButtonClass: "btn btn-secondary"
        });

        // Torno al modulo
        if (idea_id > 0) {
          this.$router.push({
            name: "processModuleIdeaDetail",
            params: {
              id: idea_id,
              process_id: this.process_id,
              phase_id: this.phase_id,
              module_id: this.module_id
            }
          });
        }
      }
    },

    setCheckedValues(av, cv) {
      // let avc = av;
      let avc = [];

      console.log("Valori totali:", av);
      console.log("Valori idea:", cv);

      const cvid = cv.map(el => {
        return el.id;
      });

      console.log("Valori totali mappati:", cv);

      for (let i = 0; i < av.length; i++) {
        if (cvid.includes(av[i].id)) {
          // av[i].checked = 'checked';
          av[i].selected = "true";
          avc.push(av[i]);
        }
      }
      console.log('Valori checkbox', avc);
      return avc;
    },
    getLocValue(field, lang) {

        if(this.localizations[lang][field] != null) {

          this.form[field] = this.localizations[lang][field];

        } else if(this.idea.localizations != null && this.idea.localizations.length > 0) {

          if(this.idea.localizations.find(loc => loc.locale == lang)) {

            this.form[field] = this.idea.localizations.find(loc => loc.locale == lang)[field];

          } else {

            this.form[field] = '';
          }

        } else {

          this.form[field] = '';
        }
    },
    setLocValue(field, event) {
      let onlyTextDescription = event?.target?.value?.replace(/<[^>]*>/g, '') ?? event.replace(/<[^>]*>/g, '');
      this.localizations[this.$refs[field].getLanguage()][field] = (event.target != null) ? onlyTextDescription :  event;
    },
    setIdeaValues() {

      console.debug('setideavalues.versioning: ', this.idea);

      this.form.title = this.idea.title;
      this.form.description = this.idea.description;
      this.form.narration = this.idea.narration;

      if(this.localizations[i18nService.getActiveLanguage()]) {
        this.form.title = this.localizations[i18nService.getActiveLanguage()].title || '';
        this.form.description = this.localizations[i18nService.getActiveLanguage()].description || '';
        this.form.address = this.localizations[i18nService.getActiveLanguage()].address || '';
        this.form.narration = this.localizations[i18nService.getActiveLanguage()].narration || '';
      }

      this.versionGroup = this.idea.versionGroup;
      this.versionNumber = this.idea.versionNumber;
      this.form.budgetMin = this.idea.budgetMin;
      this.form.scenarios = this.setCheckedValues(this.scenarios, this.idea.scenarios);
      this.form.challenges = this.setCheckedValues(this.challenges, this.idea.challenges);

      for (let item of this.idea.resources) {
        this.resources.push({
          id: item.id
        });
      }

      this.logo_original = this.idea.logo;
      this.form.url = this.idea.url;

    },

    setResources() {
      var documentSet = [];
      this.attaches = [];
      this.images = [];

      for (let r of this.idea.resources) {
        switch (r.resourceType.id) {
          case 1:
          case 2: // Documenti
            documentSet = this.attaches;
            break;
          case 3: // Immagini
            documentSet = this.images;
            // if (r.imageCategory && r.imageCategory.id == 2) {
            //   documentSet = this.logo;
            // } else {
            //   documentSet = this.images;
            // }
            break;
          case 5: // Video
            // documentSet = this.video;
            break;
          case 6: // YoutubeLink
          case 10: // Link to external service
            this.form.youtubeLink = r.uri;
            this.youtubeLink = ('//www.youtube.com/embed/' + this.getEmbededUrl(r.uri));
            this.youtubeLinkId = r.id;
            r.uri = this.youtubeLink;
            break;
        }

        if (r.resourceType.id != 10 ) {
          documentSet.push({
            id: r.id,
            name: r.originalFilename,
            title: r.localizations.find(x => x.locale == i18nService.getActiveLanguage()).title,
            uri: r.uri
          });
        }
      }
    },

    // Dropzone Methods
    // uploadProgress() {},
    // fileAdded(file) {
    //   console.log('file: ' + JSON.stringify(file));
    // },
    // sendingFiles(){},
    successUpload(file, res) {
      console.log('Aggiungo risorsa res:', res);
      console.log('uploaded file:', file);
      console.log('Aggiungo risorsa');
      this.resources.push({
        id: res.id
      });

      this.resources_added.push({
        id: res.id,
        uuid: file.upload.uuid
      });
    },

    successUploadLogo(file, res) {
      console.log('Aggiorno logo');
      this.idea.logo = res;
    },

    removeUploaded(file){
      console.log('Rimuovo risorsa file: ', file);
      // Rimuovo dalle risorse
      for( let i = 0; i < this.resources_added.length; i++){
        if ( this.resources_added[i].uuid === file.upload.uuid) {
          this.resources_added.splice(i, 1);
        }
      }
    },

    removeUploadedLogo() {
      console.log('Ripristino logo originale');
      this.idea.logo = this.logo_original;
    },

    uploadLink() {
      return new Promise((resolve, reject) => {
        let youtubeLinkData = {
          title: "Link youtube idea",
          description: "",
          uri: ('//www.youtube.com/embed/' + this.getEmbededUrl(this.form.youtubeLink))
        }
        var endpoint = this.generateUrl('/resources/extsvc/idea_content', true, false);
        //generateUrl is done
        ApiService.post(this.$apiResourceOpen + endpoint, youtubeLinkData)
            .then(res => {
              // Elimino il vecchio link
              for( var i = 0; i < this.resources.length; i++){
                if ( this.resources[i].id === this.youtubeLinkId) {
                  this.resources.splice(i, 1);
                }
              }

              // Aggiungo il nuovo link
              this.resources.push({
                id: res.data.id
              });

              resolve(res.data.id);
            })
            .catch(() => {
              // console.log("Caricamento non riuscito");
              reject(0);
            });
      });
    },
    deleteLink() {
      // Rimuovo dalle risorse
      for( let i = 0; i < this.resources.length; i++){
        if ( this.resources[i].id === this.youtubeLinkId) {
          this.resources.splice(i, 1);
        }
      }
    },

    saveIdea(put_localizations) {

      /*let ideaData = {
        id: this.id,
        process2ModuleId: this.module_id,
        ideaStatus: {
          id: this.ideaStatus
        },
        budgetMin: this.form.budgetMin,
        logo: this.idea.logo,
        centroid: this.ideaPoint,
        localizations: put_localizations,
      };*/

      let challenges_selected = [];
      let scenarios_selected = [];

      for (let item of this.form.challenges) {
        challenges_selected.push({
          id: item.id
        });
      }

      for (let item of this.form.scenarios) {
        scenarios_selected.push({
          id: item.id
        });
      }

      let onlyTextDescription = this.form.description.replace(/<[^>]*>/g, '')
      let ideaData = {
        process2ModuleId: this.module_id,
        ideaStatus: {
          id: this.ideaStatus,
        },
        title: this.form.title,
        description: onlyTextDescription,
        budgetMin: this.form.budgetMin || 0,
        challenges: challenges_selected,//Si
        scenarios: scenarios_selected,//Si
        resources: this.resources,//Si
        centroid: this.ideaPoint || null,
        address: this.ideaAddress,
        narration: this.storytelling,
        localizations: put_localizations,
        versionGroup: this.versionGroup,
        //idea2Followers: this.idea.idea2Followers,//Si
        ideaComments: [],//No
        idea2Votes: [],//No
        ideaSimpleAssessments: [], //No
        contentSubtype: (this.contentSubtypes == null || this.contentSubtypes == [] || this.contentSubtypes == '')? null : { id: this.form.contentSubtype },
      };

      if(this.idea.logo && this.idea.logo.id > 0) {
        ideaData.logo = this.idea.logo
      }

      return new Promise((resolve, reject) => {//

        console.log("ideaData", ideaData);

        var endpoint = this.generateUrl('/ideas', true, false);
        ApiService.post(this.$apiResourceOpen + endpoint + '/version', ideaData)
        .then(res => {

          this.$isLoading(false);
          resolve(res.data.id);

          /*
          let version_id = res.data.id;
          let idea2FollowersToUpload = this.idea.idea2Followers.filter(x => x.applied == true);
          console.log("idea2FollowersToUpload:", idea2FollowersToUpload);

          if(idea2FollowersToUpload && idea2FollowersToUpload.length > 0) {
            var idea2FollowersToUploadCount = 0;

            for (var f = 0; f < idea2FollowersToUpload.length; f++) {

              idea2FollowersToUpload[f].ideaId = version_id;
              idea2FollowersToUpload[f].id = null;
              var endpoint = this.generateUrl('/idea-followers', false, false);
              ApiService.post(this.$apiResourceOpen + endpoint, idea2FollowersToUpload[f])
                  .then(() => {

                    if (idea2FollowersToUploadCount == idea2FollowersToUpload.length - 1) {
                      this.$isLoading(false);
                      resolve(version_id);
                    }

                    idea2FollowersToUploadCount++;
                  })
                  .catch((e) => {
                    console.log("ERROR", e.message);
                    reject(0);
                  });
            }

          } else {

            this.$isLoading(false);
            resolve(version_id);
          }
           */
        })
        .catch((e) => {
          console.log("ERROR", e);
          reject(0);
        });
      });
    }
  }
};
</script>
